<script>
import api from "@/api";

export default {
  name: "Clients",
  data: function () {
    return {
      loading: false,
      contacts: [],
      send_id: null,
      message: "",
    };
  },
  methods: {
    checkLength() {
      if (this.m_length > 200) {
        this.message = this.message.substring(0, 200);
      }
    },
    getName(user) {
      if (user.first_name || user.last_name) {
        return user.first_name + " " + user.last_name;
      } else if (user.username) {
        return user.username;
      }
      return "---";
    },
    sendMessage(id) {
      var d = {
        message: this.message,
        user_id: id,
      };
      const url = "/sendUserMessage";
      api.Manager.axios.post(url, d).then(() => {
        this.send_id = null;
        this.message = "";
        alert("Sent");
      });
    },
    getClients() {
      this.loading = true;
      var url = "/Clients/";
      if (this.$route.params.ID) {
        url = "/Client/" + this.$route.params.ID;
      }
      api.Manager.axios.get(url).then((response) => {
        this.loading = false;
        var d = response.data;
        if (d.results) {
          d = d.results;
        }
        this.contacts = d;
      });
    },
  },
  computed: {
    m_length() {
      return 200 - this.message.length;
    },
  },
  created: function () {
    this.getClients();
  },
};
</script>
<template>
  <div id="Clients" class="col">
    <div class="row">
      <div class="col">
        <h3>Clients</h3>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Groups</th>
            <!-- <th>Send Message</th> -->
          </tr>
        </thead>

        <tbody>
          <template v-if="contacts.length > 0">
            <tr v-for="contact in contacts" :key="contact.id">
              <td>
                {{ getName(contact) }}
              </td>
              <td>{{ contact.email }}</td>
              <td>{{ contact.phone2 || contact.phone }}</td>
              <td>
                <div v-for="g in contact.groups" :key="g.name">
                  {{ g.name }}
                </div>
              </td>
              <!-- <td v-if="send_id==contact.id">
                        <div class="row">
                          <div class="col">
                            <textarea class="form-control" v-model="message" @change="checkLength" rows='5'></textarea>
                          </div>
                        </div>
                        <div class="row justify-content-between ">
                          <div class="col-4">
                            <span class="text-gray">
                              {{m_length}}
                            </span>
                          </div>
                          <div class="col-4 text-right">
                            <button @click="sendMessage(contact.id)" class="text-right btn btn-success">
                              Send
                            </button>
                          </div>
                        </div>
                      </td> -->
              <!-- <td v-else>
                        <button @click="send_id=contact.id" class="btn btn-success">
                          Send Message
                        </button>
                      </td> -->
            </tr>
          </template>
          <tr v-else-if="!loading" class="text-center">
            <td colspan="10">No Clients loaded.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss">
#Clients {
  padding-top: 10px;
  table {
    margin-top: 20px;
  }
  .text-gray {
    color: #666;
  }
  textarea {
    margin-bottom: 5px;
  }
}
</style>
